<template>
  <div id="layout">
    <div class="header">
      <Header />
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer"><Footer /></div>

    <div></div>
  </div>
</template>

<script>
import Header from './header.vue'
import Footer from './footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="less" scoped>
#layout {
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
  }
}
</style>
