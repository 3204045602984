<template>
  <div id="dash">
    <div class="top">
      <div class="wrapper">
        <div class="text">
          <div class="tit">
            <span class="line"> </span>
            <span class="title">房东管家APP</span>
          </div>
          <p>性价比极高的房屋租赁公寓管理SaaS系统</p>
          <p>专业团队做您的网络管理员 终身免费维护和升级</p>
          <p>全方位满足个人业主 职业房东 以及住房租赁企业的管理需求</p>
        </div>
      </div>
    </div>
    <div class="landlord">
      <div class="con">
        <div class="enTitle">HELP THE LANDLORD</div>
        <div class="midLine"></div>
        <div class="chTitle">专业赋能，助力房东</div>
        <div class="data-text data-text-1">
          <div>6万+</div>
          <div class="data-text-name">房东数量</div>
        </div>
        <div class="data-text data-text-2">
          <div>60万+</div>
          <div class="data-text-name">房源数量</div>
        </div>
        <div class="data-text data-text-3">
          <div>500万+</div>
          <div class="data-text-name">单日租金流水</div>
        </div>
        <div class="data-text data-text-4">
          <div>7000万+</div>
          <div class="data-text-name">月租金流水</div>
        </div>
      </div>
    </div>
    <div class="app">
      <div class="con">
        <div class="enTitle">HOUSEKEEPER</div>
        <div class="midLine"></div>
        <div class="chTitle">房东管家APP</div>
      </div>
      <div class="app-main wrapper">
        <div class="app-main-1 main">
          <img src="../../assets/images/dashboard/组 43.png" alt="" />
          <div class="app-text">
            <p class="main-title">房源管理</p>
            <p>房源是系统的原始数据，可批量添加房号、</p>
            <p>查看房源闲置率、选择房产类型等，管理多套房源，多地房产</p>
          </div>
        </div>
        <div class="app-main-2 main">
          <div class="app-text">
            <p class="main-title">租约管理</p>
            <p>在租客入住时，即可把租约内容(租金、押金、收租周期等)以及其</p>
            <p>他各种费用明细和收费标准设定好，租客在微信公众</p>
            <p>号通过手机号绑定，确认租约，便捷管理同时享受在线收租</p>
          </div>
          <img src="../../assets/images/dashboard/组 43(1).png" alt="" />
        </div>
        <div class="app-main-3 main">
          <img src="../../assets/images/dashboard/手机3.png" alt="" />
          <div class="app-text">
            <p class="main-title">账单发送</p>
            <p>租客账单、业主账单、手机系统支持四种账单发送方式：公众号、</p>
            <p>微信、短信和QQ；通过公众号，支持批量发账单和在线收租，</p>
            <p>告别纸质账单和上门收租</p>
          </div>
        </div>
      </div>
    </div>
    <div class="knowledge">
      <div class="con">
        <div class="enTitle">KNOWLEDGE</div>
        <div class="midLine"></div>
        <div class="chTitle">行业知识</div>
      </div>
      <div class="knowledge-main">
        <div class="knowledge-item">
          <div class="newsDate">
            <router-link to="/news3">
              <img src="../../assets/images/information/news1.jpeg" alt="" />
            </router-link>
            <div class="date">
              <div class="day">09</div>
              <div class="year">2021-10</div>
            </div>
          </div>
          <div class="newsCon">
            <div class="newsTitle">
              住房租赁市场回暖 监管强化助力住房租赁市场规范发展
            </div>
            <div class="newsText">
              今年三季度，此前受到新冠肺炎疫情影响的住房租赁市场逐渐回温，部分地市出现量价齐升情况，市场逐步修复。与此同时，住建部本月上旬公布了《住房租赁条例（征求意见稿）》，行业监管进一步强化。
            </div>
          </div>
        </div>
        <div class="knowledge-item pl20">
          <div class="newsDate">
            <router-link to="/news3">
              <img src="../../assets/images/information/news2.jpeg" alt="" />
            </router-link>
            <div class="date">
              <div class="day">11</div>
              <div class="year">2020-10</div>
            </div>
          </div>
          <div class="newsCon">
            <div class="newsTitle">
              六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
            </div>
            <div class="newsText">
              六省市集中整治住房租赁市场，也是对房地产市场供给侧进行改革与调整、优化与完善，不仅要让房地产市场秩序更加规范，促进房地产市场健康有序发展，也要通过供给侧供应水平的提高，让房地产市场不再因为购房比重太高、购房者太多而导致供需矛盾尖锐，影响房地产市场从调控转向长效管理。
            </div>
          </div>
        </div>
        <div class="knowledge-item pl20">
          <div class="newsDate">
            <router-link to="/news3">
              <img src="../../assets/images/information/新闻图片.png" alt="" />
            </router-link>
            <div class="date">
              <div class="day">08</div>
              <div class="year">2021-07</div>
            </div>
          </div>
          <div class="newsCon">
            <div class="newsTitle">房屋租赁经纪行业制度</div>
            <div class="newsText">
              1998年住房制度改革以来，我国房地产经纪行业快速发展，据不完全统计,全国房地产经纪机构已逾5万家,从业人员超过100万人。二手房买卖市场份额不断扩大,而房屋租赁经纪业务一直都不是大多数房地产经纪企业的主要业务。由于房地产经纪行业准入门槛低、人员流动性大、市场秩序不甚规范,一些经纪机构存在发布虚假信息、协助当事人签订阴阳合同、挪用交易资金、吃差价等不规范行为,总体来看,我国房产中介行业的社会形象并不好。
            </div>
          </div>
        </div>
        <div class="knowledge-item pl20">
          <div class="newsDate">
            <router-link to="/news3">
              <img src="../../assets/images/information/new4.jpeg" alt="" />
            </router-link>
            <div class="date">
              <div class="day">20</div>
              <div class="year">2020-12</div>
            </div>
          </div>
          <div class="newsCon">
            <div class="newsTitle">中央加快住房租赁市场立法</div>
            <div class="newsText">
              12月16日正式闭幕的中央经济工作会议，对中国房地产市场提出了新的定位和工作部署，“房子是用来住的，不是用来炒的”这一表述，被看作是在减弱住房金融属性。同时，会议在抑制房地产市场泡沫、增加土地供应、落实人地挂钩等方面的部署，也决定着明年楼市调控的基调和市场走势。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#dash {
  user-select: none;
  .con {
    text-align: center;
    padding-top: 64px;
    .enTitle {
      font-size: 30px;
      font-weight: bold;
      color: #5e5e5e;
      line-height: 50px;
      opacity: 0.5;
    }
    .midLine {
      width: 50px;
      height: 4px;
      background: #015cad;
      margin: 10px auto;
    }
    .chTitle {
      font-size: 34px;
      font-weight: 700;
      color: #0e2645;
      line-height: 50px;
      text-shadow: 0.1em 0.1em 0.1em rgb(122, 122, 122);
    }
  }
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 650px;
    width: 100%;
    background-image: url(../../assets/images/dashboard/banner背景.png);
    background-size: cover;
    .text {
      color: #fff;
      position: absolute;
      top: 35%;
      .tit {
        padding-bottom: 20px;
        .line {
          position: absolute;
          top: 3px;
          border-radius: 3px;
          width: 6px;
          height: 50px;
          background-color: #00b0ff;
          display: inline-block;
        }
        .title {
          padding-left: 30px;
          font-size: 48px;
          text-shadow: 0.1em 0.1em 0.1em rgb(0, 0, 0);
        }
      }
      p {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  .landlord {
    position: relative;
    height: 650px;
    background-image: url(../../assets/images/dashboard/助力房东背景.png);
    background-size: cover;
    .data-text {
      position: absolute;
      font-size: 50px;
      color: #041122;
      text-align: center;
      font-weight: 700;
    }
    .data-text-name {
      color: #575c61;
      font-weight: 400;
      font-size: 24px;
      padding-top: 10px;
    }
    .data-text-1 {
      left: 32.55208333%;
      top: 39.86135182%;
    }
    .data-text-2 {
      left: 61.04166667%;
      top: 39.86135182%;
    }
    .data-text-3 {
      left: 30.88541667%;
      top: 69.8440208%;
    }
    .data-text-4 {
      left: 59.60625%;
      top: 69.8440208%;
    }
  }
  .app {
    padding-bottom: 68px;
    .app-text {
      font-size: 16px;
      color: #777;
      line-height: 18px;
      .main-title {
        font-size: 20px;
        font-weight: 500;
        color: #011630;
        line-height: 36px;
      }
    }
    .app-main-1,
    .app-main-3 {
      .app-text {
        box-shadow: 3px 0px 8px #a7a5a5;
        width: 600px;
        height: 180px;
        box-sizing: border-box;
        padding-left: 40px;
      }
    }
    .app-main-2 {
      padding-left: 30%;
      .app-text {
        text-align: right;
      }
      img {
        padding-left: 3.3%;
      }
    }

    img {
      width: 336px;
      height: 260px;
    }
    .app-main {
      position: relative;
      margin-top: 26px;
      .main {
        display: flex;
        align-items: center;
      }
    }
  }
  .knowledge {
    height: 640px;
    background: #f2f6fa;
    .knowledge-main {
      display: flex;
      justify-content: center;
    }
    .knowledge-item {
      width: 285px;
      height: 307px;
      margin-top: 50px;
      .newsDate {
        position: relative;
        img {
          width: 282px;
          height: 194px;
        }
        img:hover {
          transform: scale(1.03);
        }
        .date {
          position: absolute;
          top: 0;
          text-align: center;
          color: #fff;
          line-height: 20px;
          padding: 5px 10px;
          width: 50px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.582);
          .year {
            font-size: 12px;
          }
          .day {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
      .newsCon {
        .newsTitle {
          font-size: 16px;
          font-weight: 400;
          color: #444444;
          line-height: 38px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        .newsTitle:hover {
          color: #00b0ff;
        }
        .newsText {
          font-size: 12px;
          font-weight: 400;
          color: #888888;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
