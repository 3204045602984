import Vue from 'vue'
import VueRouter from 'vue-router'
import Dash from '../views/Dashboard'
import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dash',
    component: Layout,
    children: [
      {
        path: '/dash',
        component: Dash,
        meta: {
          title: '【房东管家】-首页'
        }
      },
      {
        path: '/information',
        component: () =>
          import(/* webpackChunkName: "Information" */ '../views/Information'),
        meta: {
          title: '【房东管家】-资讯'
        }
      },
      {
        path: '/help',
        component: () => import(/* webpackChunkName: "Help" */ '../views/Help'),
        meta: {
          title: '【房东管家】-帮助'
        }
      },
      {
        path: '/our',
        component: () => import(/* webpackChunkName: "Our" */ '../views/Our'),
        meta: {
          title: '关于我们'
        }
      },
      {
        path: '/news1',
        component: () => import('../views/Information/news1.vue'),
        meta: {
          title: '【房东管家】-资讯'
        }
      },
      {
        path: '/news2',
        component: () => import('../views/Information/news2.vue'),
        meta: {
          title: '【房东管家】-资讯'
        }
      },
      {
        path: '/news3',
        component: () => import('../views/Information/news3.vue'),
        meta: {
          title: '【房东管家】-资讯'
        }
      },
      {
        path: '/news4',
        component: () => import('../views/Information/news4.vue'),
        meta: {
          title: '【房东管家】-资讯'
        }
      },
      {
        path: '/news5',
        component: () => import('../views/Information/news5.vue'),
        meta: {
          title: '【房东管家】-帮助'
        }
      },
      {
        path: '/help1',
        component: () => import('../views/Help/help1.vue'),
        meta: {
          title: '【房东管家】-帮助'
        }
      },
      {
        path: '/help2',
        component: () => import('../views/Help/help2.vue')
      },
      {
        path: '/help3',
        component: () => import('../views/Help/help3.vue'),
        meta: {
          title: '【房东管家】-帮助'
        }
      },
      {
        path: '/help4',
        component: () => import('../views/Help/help4.vue'),
        meta: {
          title: '【房东管家】-帮助'
        }
      }
    ]
  }
]
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

const router = new VueRouter({
  routes
})

router.afterEach((to, from, next) => {
  document.title = to.meta.title
  window.scrollTo(0, 0)
})

export default router
