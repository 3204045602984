<template>
  <div id="header" :style="style" class="header">
    <div class="top">
      <div class="wrapper nav">
        <div>
          <router-link to="/dash">
            <img src="@/assets/images/dashboard/组 1.png" alt="" />
          </router-link>
        </div>
        <el-menu
          router
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-menu-item style="background-color: transparent" index="/dash"
            >首页</el-menu-item
          >
          <el-menu-item
            style="background-color: transparent"
            index="/information"
            >资讯</el-menu-item
          >
          <el-menu-item style="background-color: transparent" index="/help"
            >帮助</el-menu-item
          >
          <el-menu-item style="background-color: transparent" index="/our"
            >我们</el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curIndex: this.$route.path, // 当前激活的菜单索引
      activeIndex: '/dash',
      style: {
        backgroundColor: 'transparent'
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.fetchData()
  },
  methods: {
    fetchData() {
      const curPath = this.$route.path
      console.log(curPath)
      if (curPath === '/dash') {
        this.activeIndex = '/dash'
      } else if (
        (curPath === '/information') |
        (curPath === '/news1') |
        (curPath === '/news2') |
        (curPath === '/news3') |
        (curPath === '/news4')
      ) {
        this.activeIndex = '/information'
      } else if (
        (curPath === '/help') |
        (curPath === '/help1') |
        (curPath === '/help2') |
        (curPath === '/help3') |
        (curPath === '/help4')
      ) {
        this.activeIndex = '/help'
      } else if (curPath === '/our') {
        this.activeIndex = '/our'
      }
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // console.log(scrollTop)
      if (scrollTop > 50) {
        this.style.backgroundColor = 'rgb(20, 76, 137)'
      } else if (scrollTop === 0) {
        this.style.backgroundColor = 'transparent'
      }
    }
  },
  watch: {
    $route: 'fetchData'
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="less" scoped>
#header {
  transition: background linear 0.5s;
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 999;
  .top {
    height: 80px;
    background-color: transparent;
    .nav {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        height: 44px;
      }
      .el-menu {
        display: flex;
        color: white;
        background-color: transparent;
        height: 80px;
        .el-menu-item {
          margin-right: 30px;
          line-height: 80px;
          font-size: 18px;
          height: 80px;
        }
      }
      .el-menu-item:focus,
      .el-menu-item:hover {
        outline: 0;
        background-color: transparent;
      }
      .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 4px solid #fff;
        color: #303133;
      }
      .el-menu.el-menu--horizontal {
        border-bottom: solid 1px transparent;
      }
    }
  }
}
</style>
