<template>
  <div id="footer">
    <div class="content wrapper">
      <div class="left">
        <img src="@/assets/images/dashboard/组 1.png" alt="" />
        <p>
          Copyright weilan.com All right reserved.2020 -
          武汉卫澜环保科技版权所有
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index"
            >鄂ICP备2020016655号</a
          >
        </p>
      </div>
      <div class="middle">
        <p>
          <span class="iconfont icon-24gf-telephone2"></span>联系电话：+027
          88688868
        </p>
        <p>
          <span class="iconfont icon-zuobiaofill"></span
          >地址：湖北省武汉市洪山区洪山创业中心4238室
        </p>
      </div>
      <!-- <div class="right">
        <div class="QRcode"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#footer {
  width: 100%;
  height: 200px;
  background: #282828;
  p {
    line-height: 30px;
  }
  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .left {
      p {
        font-size: 12px;
        color: #999;
        a {
          font-size: 12px;
          color: #999;
          cursor: pointer;
        }
      }
    }
    .middle {
      color: #fff;
      font-size: 12px;
    }
    .right {
      .QRcode {
        width: 120px;
        height: 120px;
        background-color: #fff;
      }
    }
  }
}
</style>
